<template>
  <b-card>
    <b-form
      novalidate
      @submit.prevent="onSubmit"
    >
      <b-row>
        <b-col
          md="12"
        >
          <b-row
            md="8"
          >
            <b-col>
              <b-form-group
                class="form-group-el-select"
                label="Точка отправления"
              >
                <el-select-clearable
                  v-model="firstPoint"
                  class="form-control"
                  name="firstPoint"
                  placeholder="Начните вводить код маршрута"
                  clearable
                  :filterable="true"
                  :remote="true"
                  :remote-method="getFirstDepartmentsList"
                  :loading="loading"
                  :disabled="true"
                  @clear="getFirstDepartmentsList"
                >
                  <el-option
                    v-for="item in firstDepartments"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                class="form-group-el-select"
                label="Конечная точка"
              >
                <el-select-clearable
                  v-model="lastPoint"
                  class="form-control"
                  name="lastPoint"
                  placeholder="Начните вводить код маршрута"
                  clearable
                  :filterable="true"
                  :remote="true"
                  :remote-method="getLastDepartmentsList"
                  :loading="loading"
                  :disabled="true"
                  @clear="getLastDepartmentsList"
                >
                  <el-option
                    v-for="item in lastDepartments"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                class="form-group-el-select"
                label="Основные габариты ТС"
              >
                <el-select-clearable
                  v-model="mainDimension"
                  class="form-control"
                  name="dimensions"
                  placeholder="Начните вводить наименование основного габарита"
                  clearable
                  :filterable="true"
                  :remote="true"
                  :remote-method="getAllDimensions"
                  :loading="loading"
                  @clear="getMainDimensions"
                  @focus="getMainDimensions"
                  @input="getMainDimensions"
                >
                  <el-option
                    v-for="item in mainDimensions"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Дополнительные габариты ТС"
              >
                <el-select-clearable
                  v-model="optionalDimension"
                  name="optionalDimensions"
                  class="w-100"
                  placeholder="Начните вводить наименование дополнительного габарита"
                  clearable
                  :multiple="true"
                  :filterable="true"
                  :remote="true"
                  :remote-method="getAllDimensions"
                  :loading="loading"
                  @clear="getAllDimensions"
                  @focus="getOptionalDimensions"
                  @input="getOptionalDimensions"
                >
                  <el-option
                    v-for="item in optionalDimensions"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
            <b-col class="d-flex align-items-center">
              <b-button
                type="submit"
                variant="primary"
                class="mr-2 mt-2"
                :class="{'loading' : loading}"
              >
                Сохранить связку
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import ElSelectClearable from '../../components/ElSelectClearable.vue';
import {routeWayDimensionUpdate,
  routeWayDimensionView,
  departmentsList,
  transportVehiclesDimensionsTypes,
} from '../../services/api';
import notifications from '../../components/mixins/notifications';

export default {
  components: {
    ElSelectClearable,
  },
  mixins: [notifications],
  data() {
    return {
      loading: false,
      firstPoint: null,
      lastPoint: null,
      dimension: null,
      mainDimension: null,
      optionalDimension: null,
      mainDimensions: [],
      optionalDimensions: [],
      firstDepartments: [],
      lastDepartments: [],
    };
  },
  computed: {
    formStrAdd() {
      const calculationFormData = {};
      if (this.firstPoint) {
        calculationFormData.firstPoint = {id: this.firstPoint};
      }
      if (this.lastPoint) {
        calculationFormData.lastPoint = {id: this.lastPoint};
      }
      if (this.mainDimension) {
        calculationFormData.dimension = {id: this.mainDimension};
      }
      if (this.optionalDimension) {
        calculationFormData.optionalDimensions = this.optionalDimension.map((item) => {
          return {id: item};
        });
      }
      return calculationFormData;
    },
  },
  mounted() {
    this.getFirstDepartmentsList();
    this.getLastDepartmentsList();
    this.getAllDimensions();
    this.getDimension();
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      const response = await routeWayDimensionUpdate(this.$store.state.user.ownerId, this.$route.params.dimensionId, this.formStrAdd);
      if (response && response.status === 200) {
        this.showSuccess('Связка маршрут-габарит обновлена');
        this.$router.push({path: '/route-way-dimensions/customer'});
      }
      this.loading = false;
    },
    async getFirstDepartmentsList(name) {
      this.loading = true;
      const params = {limit: 100};
      if (name) {
        params.name = name;
      }
      const response = await departmentsList(params);
      if (response && response.status === 200) {
        this.firstDepartments = response.data.items.map((item) => {
          return {
            value: item.id,
            text: item.code,
          };
        });
      }
      this.loading = false;
    },
    async getLastDepartmentsList(name) {
      this.loading = true;
      const params = {limit: 100};
      if (name) {
        params.name = name;
      }
      const response = await departmentsList(params);
      if (response && response.status === 200) {
        this.lastDepartments = response.data.items.map((item) => {
          return {
            value: item.id,
            text: item.code,
          };
        });
      }
      this.loading = false;
    },
    async getDimension() {
      this.loading = true;
      const response = await routeWayDimensionView(this.$store.state.user.ownerId, this.$route.params.dimensionId);
      if (response && response.status === 200) {
        this.optionalDimension = response.data.optionalDimensions.map((item) => {
          return item.id;
        });
        this.mainDimension = response.data.dimension.id;
        this.firstPoint = response.data.firstPoint.id;
        this.firstDepartments.push({
          value: response.data.firstPoint.id,
          text: response.data.firstPoint.code,
        });
        this.lastPoint = response.data.lastPoint.id;
        this.lastDepartments.push({
          value: response.data.lastPoint.id,
          text: response.data.lastPoint.code,
        });
      }
      this.loading = false;
    },
    async getAllDimensions() {
      this.loading = true;
      const response = await transportVehiclesDimensionsTypes({limit: 10});
      if (response && response.status === 200) {
        this.optionalDimensions = this.mainDimensions = response.data.items.map((item) => {
          return {value: item.id, text: item.name};
        });
      }
      this.loading = false;
    },
    getOptionalDimensions() {
      this.loading = true;
      this.optionalDimensions = this.mainDimensions.filter((item) => (item.value != this.mainDimension));
      this.loading = false;
    },
    getMainDimensions() {
      this.loading = true;
      this.mainDimensions = this.mainDimensions.filter((item) => !(this.optionalDimension.includes(item.value)));
      this.loading = false;
    },
  },
};
</script>